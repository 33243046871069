
$(function(){
	$("button").on('click',function(){
		$(this).blur()
	})
	$("[title]").tooltip();

	$(".search-toggle-btn").on('click',function(){
		$("#search-modal").toggleClass("d-none");
		setTimeout(function(){
			if($("#search-modal").hasClass("d-none")){
				$("#search-modal").removeClass("active")
			}else{
				$("#search-modal").addClass("active")
			}
		},1)
		
	})
	
	set_chart_overflow()
	$(window).resize(function() {
		set_chart_overflow()
	});

	function set_chart_overflow(){
		$(".chart-container").each(function(){
			if ($(this)[0].scrollWidth > $(this)[0].clientWidth) {
				$(this)[0].style.overflowX = 'auto';
			}else{
				$(this)[0].style.overflowX = 'hidden';
			}
		})
	}

	// sidebar位置
	function setSidebarPosition() {
		$(".sidebar").css("top", $(window).height() - $(".sidebar").height() - 100);
	}
	setSidebarPosition();
	$(window).on('resize', function() {
		setSidebarPosition();
	});
	
})