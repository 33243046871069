import select2 from 'select2';
import 'select2/src/scss/core.scss';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';

document.addEventListener("DOMContentLoaded", function() {

	$( ".js-select2" ).select2({
		theme: "bootstrap",
		width: '100%'
	});

  $(".js-select2-tag").select2({
    placeholder: "輸入tag",
    allowClear: false,
    theme: "bootstrap",
		width: '100%',
    tags: true,
    tokenSeparators: [','],
    createTag: function(params) {
      if (params.term.indexOf('，') > 0 || params.term.indexOf(',') > 0) {
        var str = params.term;
        str = str.substr(0, str.length - 1);
        return {
          id: str,
          text: str
        }
      } else {
        return null;
      }
    }
  });

});